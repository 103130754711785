import React from "react";
import Layout from "../components/layout";
import AddressAndTransport from "../components/AddressAndTransport";
import HTMLReactParser from "html-react-parser";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import Slider from "react-slick";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { parseNestedHTML } from "../Utils";
import ContactForm from "../components/contactForm";
import SafeHtmlParser from "../components/safe-html-parser";
const settings = {
	dots: true,
	dotsClass: "slick-dots slick-thumb",
	infinite: true,
	speed: 1000,
	slidesToShow: 1,
	slidesToScroll: 1,
	arrows: false,
	autoplay: true,
};
const ContactUs = () => {
	const data = useStaticQuery(graphql`
		query {
			wpContactUs {
				contactUsFields {
					address {
						btnText
						location {
							lat
							lng
						}
						title
					}
					getInTouch {
						body
						heading1
						heading1Body
						title
					}
					heroSection {
						btnText
						title
						slider {
							image {
								node {
									altText
									localFile {
										publicURL
										childImageSharp {
											gatsbyImageData(
												formats: [AUTO, WEBP]
												quality: 100
												transformOptions: { cropFocus: CENTER, fit: CONTAIN }
												layout: CONSTRAINED
												placeholder: BLURRED
											)
										}
									}
								}
							}
						}
					}
					vehicleStatus {
						body
						title
						icon {
							node {
								sourceUrl
								altText
								localFile {
									publicURL
									childImageSharp {
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
					}
				}
				seoFields {
					localBusinessSchema
					metaDescription
					opengraphDescription
					opengraphTitle
					title
					image {
						node {
							altText
							localFile {
								publicURL
								childImageSharp {
									original {
										height
										width
									}
									gatsbyImageData(
										formats: [AUTO, WEBP]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
					}
				}
			}
			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);
	const siteUrl = data.site.siteMetadata.siteUrl;
	// console.log(data);
	const ContactPage = data.wpContactUs.contactUsFields;
	const seoFields = data.wpContactUs.seoFields;
	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Contact Us",
				item: {
					url: `${siteUrl}/contact-us`,
					id: `${siteUrl}/contact-us`,
				},
			},
		],
	};
	return (
		<div>
			<Helmet>
				<script src="https://www.google.com/recaptcha/api.js" async defer />
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			{/* <LocalBusinessSchema /> */}
			<GatsbySeo
				title={seoFields?.title}
				description={seoFields?.metaDescription}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/contact-us`,
					title: seoFields?.opengraphTitle,
					description: seoFields?.opengraphDescription,
					images: [
						{
							url: `${seoFields?.image?.node?.localFile.publicURL}`,
							width: `${seoFields?.image?.node?.localFile.childImageSharp.original.width}`,
							height: `${seoFields?.image?.node?.localFile.childImageSharp.original.height}`,
							alt: `${seoFields?.image?.node?.altText}`,
						},
					],
				}}
			/>
			<Layout>
				<section className="position-relative ">
					<div className="position-relative hero-carousal">
						<div
							style={{
								zIndex: 1,
								background:
									"linear-gradient(0deg, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.40) 100%)",
								borderTop: "1px solid #FFF",
								borderBottom: "1px solid #FFF",
							}}
							className="position-absolute h-100 w-100"
						>
							<Container className="h-100">
								<Row className="align-items-center h-100">
									<Col>
										<div className="h-100 ">
											<div className="stylishText text-uppercase  ">
												<SafeHtmlParser
													htmlContent={ContactPage.heroSection.title}
												/>
											</div>
											<Button
												href="#form"
												className="text-white text-uppercase  w-100 w-md-auto fs-5 px-4 py-2"
											>
												{ContactPage.heroSection.btnText}
											</Button>
										</div>
									</Col>
								</Row>
							</Container>
						</div>
						<div className="slider-container">
							<Slider {...settings}>
								{ContactPage.heroSection.slider.map((slider, i) => (
									<div key={i}>
										<GatsbyImage
											className="images-height"
											image={
												slider.image?.node.localFile?.childImageSharp
													?.gatsbyImageData
											}
											style={{
												objectFit: "cover",
												width: "100%",
											}}
											alt={slider.image?.node?.altText}
										/>
									</div>
								))}
							</Slider>
						</div>
					</div>
				</section>

				<section className="pt-5 pt-lg-7">
					<Container>
						<Row>
							<Col>
								{" "}
								<h2 className="display-5 pb-3 text-uppercase">
									{ContactPage.getInTouch.title}
								</h2>
								<SafeHtmlParser htmlContent={ContactPage.getInTouch.body} />
								<h3 className="fs-4">{ContactPage.getInTouch.heading1}</h3>
								<div>
									<SafeHtmlParser
										htmlContent={ContactPage.getInTouch.heading1Body}
									/>
								</div>{" "}
							</Col>
						</Row>
					</Container>
				</section>
				<section className="py-5" id="form">
					<Container>
						<Row>
							<Col>
								<ContactForm />
							</Col>
						</Row>
					</Container>
				</section>
				<section className="pb-5 pb-lg-7">
					<AddressAndTransport
						address={ContactPage.address}
						vehiclesStatus={ContactPage.vehicleStatus}
					/>
				</section>
			</Layout>
		</div>
	);
};

export default ContactUs;
